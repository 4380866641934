
function initialize() {
    // Basic options for a simple Google Map
    // For more options see: https://developers.google.com/maps/documentation/javascript/reference#MapOptions
    var mapOptions = {
        // How zoomed in you want the map to start at (always required)
        zoom: 11,

        // The latitude and longitude to center the map (always required)
        center: new google.maps.LatLng(46.3762892,11.6583885),

        // How you would like to style the map.
        // This is where you would paste any style found on Snazzy Maps.
        styles: [{"featureType":"landscape","stylers":[{"saturation":-100},{"lightness":65},{"visibility":"on"}]},{"featureType":"poi","stylers":[{"saturation":-100},{"lightness":51},{"visibility":"simplified"}]},{"featureType":"road.highway","stylers":[{"saturation":-100},{"visibility":"simplified"}]},{"featureType":"road.arterial","stylers":[{"saturation":-100},{"lightness":30},{"visibility":"on"}]},{"featureType":"road.local","stylers":[{"saturation":-100},{"lightness":40},{"visibility":"on"}]},{"featureType":"transit","stylers":[{"saturation":-100},{"visibility":"simplified"}]},{"featureType":"administrative.province","stylers":[{"visibility":"off"}]},{"featureType":"water","elementType":"labels","stylers":[{"visibility":"on"},{"lightness":-25},{"saturation":-100}]},{"featureType":"water","elementType":"geometry","stylers":[{"hue":"#ffff00"},{"lightness":-25},{"saturation":-97}]}]
    };

    // Get the HTML DOM element that will contain your map
    // We are using a div with id="map" seen below in the <body>
    var mapElement = document.getElementById('map');

    // Create the Google Map using our element and options defined above
    var map = new google.maps.Map(mapElement, mapOptions);

    // Let's also add a marker while we're at it
    var marker = new google.maps.Marker({
        position: new google.maps.LatLng(46.3762892,11.6583885),
        map: map,
        title: 'J Mountain'
    });

}

google.maps.event.addDomListener(window, 'load', initialize);


$(document).ready(function() {

    $.cookieBar({
        fixed: true,
        message: 'Questo sito utilizza i cookie per migliorare la tua esperienza. ',
        acceptText: "Ok",
        policyText: "Privacy Policy",
        policyButton: true,
        policyURL: "//www.iubenda.com/privacy-policy/938444"
    });

    $('input[name="ingruppo"]').change(function() {
        if($('input[name="ingruppo"]').is(':checked')) {
            $('.ingruppo').stop().slideDown(500);
        }
        else {
            $('.ingruppo').stop().slideUp(500);
        }
    });

    $('#addPeople').click(function() {
        $('.groupcopy:first').clone().insertAfter($('.groupcopy')).show();
    });


    $(document).on('click', '.close', function() {
        //if($('.groupcopy').length > 1) {
            $(this).parent().parent().parent().hide();
        //}
    });

    $('#sendrequest').click(function() {
        $('.groupcopy').each(function() {
            if($(this).is(":visible") == false) {
                $(this).remove();
            }
        });

        //return false;
    });
});